.map-container {
  height: 100%;
  width: 100%;
}

.container {
  width: 1024px;
  height: 1024px;
  background-color: yellow;
}

.markerContent {
  position: absolute;
  top: -50px;
  left: 2px;

  width: 133px;
  height: 40px;
  background-color: black;
  border-radius: 100px;
  color: white;

  font-family: "Brown";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.markerContent::before {
  content: "";
  position: absolute;

  width: 1px;
  height: 25px;
  left: 1px;

  background-color: black;
  top: 25px;
}

.marker {
  display: block;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  padding: 0;
  width: 10;
  height: 10;
  box-sizing: border-box;
  border: 1px solid black;
}
