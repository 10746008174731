@import url("https://fonts.googleapis.com/css2?family=Arvo:ital,wght@0,400;0,700;1,400;1,700&display=swap");

.page_404 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 0;
  background: #fff;
  font-family: 'Arvo', serif !important;
  height: 100vh;
}

.page_container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
}

@media (min-width: 1200px) {
  .page_container {
    width: 1170px;
  }
}

@media (min-width: 992px) {
  .page_container {
    width: 970px;
  }
}

@media (min-width: 768px) {
  .page_container {
    width: 750px;
  }
}

.page_404 img {
  width: 100%;
}

.page_404 a {
  text-decoration: none;
}

.four_zero_four_bg {
  background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
  height: 400px;
  background-position: center;
}

.four_zero_four_bg h1 {
  font-size: 80px;
  font-family: 'Arvo', serif !important;
}

.page_404 .h2, .page_404 p, .page_404 a {
  font-family: 'Arvo', serif !important;
}

.four_zero_four_bg h3 {
  font-size: 80px;
  font-family: 'Arvo', serif !important;
}

.link_404 {
  color: #fff !important;
  padding: 10px 20px;
  background: #39ac31;
  margin: 20px 0;
  display: inline-block;
}

.contant_box_404 {
  margin-top: -50px;
}

/* Media Queries for responsiveness */

@media (min-width: 768px) {
  .col-sm-10 {
    width: 83.33333333%;
  }
  
  .col-sm-offset-1 {
    margin-left: 8.33333333%;
  }
  
  .col-sm-12 {
    width: 100%;
  }
}

.row {
  margin-right: -15px;
  margin-left: -15px;
}
