* {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body::-webkit-scrollbar { display: none; }

::-webkit-scrollbar {
  background-color: #fff;
  width: 16px;
}

/* background of the scrollbar except button or resizer */
::-webkit-scrollbar-track {
  background-color: #fff;
}

/* scrollbar itself */
::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 4px solid #fff;
}

/* set button(top and bottom of the scrollbar) */
::-webkit-scrollbar-button {
  display:none;
}

.MuiClock-pin {
  background-color: red;
}

.MuiSvgIcon-fontSizeMedium {
  color: grey
}

.new-vote-animation{
  animation: blinker 0.8s linear infinite;
}

.MuiAccordionSummary-content{
  margin: 0 !important;
}

.MuiAccordionSummary-content.Mui-expanded{
  margin: 0 0 12px 0 !important;
}

/* Animations */

@keyframes blinker {
  50% { opacity: 0; }
}

@keyframes pulse {
	0% {
		transform: scale(0.8);
		box-shadow: 0 0 0 0 rgba(52, 199, 89, 0.7)
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 8px rgba(52, 199, 89, 0)
	}

	100% {
		transform: scale(0.8);
		box-shadow: 0 0 0 0 rgba(52, 199, 89, 0)
	}
}
